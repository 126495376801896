<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Edit Asset</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
           

          <v-flex xs12 align-self-center fill-height pa-0  >
            <v-card tile flat>
              <v-form v-model="addcat2" ref="addcat2">
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="name"
                      label="Asset Name"
                      :rules="[rules.required]"
                      required
                      autofocus
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetCategoryId"
                      :items="categoryList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose asset category"
                    >
                    </v-select>
                  </v-flex>
                  
                   
                  <v-flex xs12 align-self-center text-left pa-2>
                    <v-textarea
                      v-model="description"
                      label="Description"
                      hide-details
                      outlined
                      type="number"
                      dense
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetType"
                      :items="typeList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose asset type"
                    >
                    </v-select>
                  </v-flex>
                   <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="warning_minStock"
                      label="Minimum stock reminder value" 
                       
                      type="number"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>

                
 
                  <v-flex xs12   align-self-center pa-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm6 md3 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="validateInput()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,

      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable","Semiconsumable"], 
      description: null, 
      quantity: null,
      assetId: null,
      asset:null,
      warning_minStock:null,
    };
  },
  watch: {
    radios() {
      console.log("Rad=", this.radios);
      this.clearOff()
    },
  },
  beforeMount() {
    this.getCategories();
    this.getData(); 
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/asset/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.asset = response.data.data;
          this.assetCategoryId=response.data.data.assetCategoryId
          this.name=response.data.data.name
          this.description=response.data.data.description
          this.assetType=response.data.data.assetType
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategories() {
      this.appLoading = true;
      axios({
        url: "/assetCategory/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

     

    validateInput() { 
        if (!this.name) {
          this.msg = "Please provide asset name";
          this.showsnackbar = true;
          return;
        } else if (!this.assetCategoryId) {
          this.msg = "Please provide asset category Id";
          this.showsnackbar = true;
          return;
        } else if (!this.assetType) {
          this.msg = "Please provide asset type";
          this.showsnackbar = true;
          return;
        }  else {
          this.editAsset();
        }

        
     
      
       
    },
    editAsset() {
      // var data = {};
      // data["name"] = this.name;
      axios({
        url: "/asset/edit",
        method: "POST",
        data: {
          id:this.$route.query.id,
          assetCategoryId: this.assetCategoryId,
          name:this.name,
          assetType:this.assetType,
          description:this.description, 
          warning_minStock:this.warning_minStock
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true; 
            this.dialog = false;
            this.getData(); 
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    

   
  },
};
</script>
